import { Component, Inject, Input } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';

@Component({
  selector: 'qr-tag',
  templateUrl: './qr-tag.component.html',
  styleUrls: ['./qr-tag.component.scss'],
  standalone: true,
  imports: [L10nTranslationModule],
})
export class QrTagComponent {
  @Input() text!: string;
  @Input() color!: string;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}
}
