import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'qr-icon-button',
  templateUrl: './qr-icon-button.component.html',
  styleUrls: ['./qr-icon-button.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
})
export class QrIconButtonComponent implements OnChanges {
  @Input() icon = 'heart';
  @Input() darkmode = false;
  @Input() config: any; // Configuraciones del botón que llegan desde el componente padre
  style: string = '';
  @Input() ariaLabel?: string;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    if (this.config) {
      this.style = this.config.style;
    }
  }
}
