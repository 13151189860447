<button
  class="qr-icon-button"
  [ngClass]="{ darktheme: darkmode }"
  [class]="this.style"
  matRipple
  [attr.aria-label]="this.ariaLabel ? this.ariaLabel : ''"
  aria-hidden="true"
  [matTooltip]="config.iconTooltipOnly">
  <div [class]="icon"></div>
</button>
